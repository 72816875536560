import React from 'react';
import {compose} from 'redux';
import {withI18nRouting} from '@foes/react-i18n-routing';
import {Link} from 'react-router-dom';
import {HashLink} from 'react-router-hash-link';
import scrollToElement from 'scroll-to-element';
import PropTypes from 'prop-types';

import i18n from './../../../i18n';
import isExternalUrl from './../../_util/isExternalUrl';
import replaceURLForPhoneOrMail from './../../_util/replaceURLForPhoneOrMail';

const ensureInitialSlash = slug => (slug && slug.substring(0, 1) !== '/' ? `/${slug}` : slug);

const CmsLink = ({children, className, extrainfo, forceExternal, slug, i18nRouting, ...rest}) => {
  slug = slug.trim();
  return forceExternal || isExternalUrl(slug) ? (
    <a
      className={className}
      href={replaceURLForPhoneOrMail(slug)}
      rel={extrainfo && extrainfo.rel ? extrainfo.rel : 'noopener noreferrer nofollow'}
      target={extrainfo && extrainfo.target ? extrainfo.target : '_blank'}
      {...rest}
    >
      {children}
    </a>
  ) : slug && slug.substring(0, 1) === '#' ? (
    <HashLink scroll={() => scrollToElement(slug, {ease: 'inOutBack', duration: 200, offset: -96})} to={slug}>
      {children}
    </HashLink>
  ) : (
    <Link to={i18n.prependLocale(i18nRouting.locale, ensureInitialSlash(slug))} {...rest}>
      {children}
    </Link>
  );
};

CmsLink.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  forceExternal: PropTypes.bool,
  slug: PropTypes.string.isRequired,
};

CmsLink.defaultProps = {
  forceExternal: false,
};

export default compose(
  withI18nRouting,
  React.memo,
)(CmsLink);

import React from 'react';
import {compose} from 'redux';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';

import {getFooterMenu} from './../../../store/Api/ApiReducer';

import Button from '../../atoms/Button/Button';
import InlineButton from './../../atoms/InlineButton/InlineButton';
import Typography, {VARIANT} from './../../atoms/Typography/Typography';

import SocialLinks from './../../components/SocialLinks/SocialLinks';
import ContactForm from './../../pages/BackendPage/Contact/ContactForm';

import Column from './../Column/Column';
import Grid from './../Grid/Grid';

import './Footer.css';
import ContentWithSidebar from '../ContentWithSidebar/ContentWithSidebar';

const FooterTitle = ({title}) => (
  <ContentWithSidebar>
    <Column className="footer__title">
      <div className="typography typography--h1" dangerouslySetInnerHTML={{__html: title}}></div>
    </Column>
  </ContentWithSidebar>
);

const LegalMenu = ({items}) => (
  <ContentWithSidebar>
    <Column className="footer__gdpr">
      <Typography className="footer__copyright" variant={VARIANT.SMALL_BODY}>
        &copy; {`${new Date().getFullYear().toString()} LIN3S`}
      </Typography>
      {items.map(item => (
        <Link className="footer__legal-link" key={item.slug} to={item.slug}>
          <InlineButton hideUnderline={true} variant={VARIANT.SMALL_BODY} withHover={true}>
            {item.title}
          </InlineButton>
        </Link>
      ))}
    </Column>
  </ContentWithSidebar>
);

const Footer = ({footerMenu, settings, shouldHideFooterFields}) => {
  if (!footerMenu) {
    return null;
  }
  const {items, socialLinks} = footerMenu;
  var mainClass = 'footer';
  if (typeof shouldHideFooterFields !== 'undefined' && true === shouldHideFooterFields) {
    mainClass += ' footer--hide-footer-fields';
  }

  return (
    <footer className={mainClass}>
      {(typeof shouldHideFooterFields === 'undefined' || false === shouldHideFooterFields) &&
        Object.keys(settings).length > 0 && (
          <>
            <FooterTitle title={settings.footerCta.title} />
            <ContentWithSidebar>
              <Grid
                className="footer__row"
                columnsPerBreakpoint={{S: 1, M: 2}}
                itemClassName="footer__column"
                scrollAnimation={false}
              >
                <Link to={settings.footerCta.ctaUrl}>
                  <Button>{settings.footerCta.ctaText}</Button>
                </Link>
              </Grid>
              <Grid
                className="footer__row"
                columnsPerBreakpoint={{S: 1, M: 2}}
                itemClassName="footer__column"
                scrollAnimation={false}
              >
                {settings.joinUs && settings.joinUs.ctaUrl && (
                  <React.Fragment>
                    <Typography variant={VARIANT.BIG_BODY}>{settings.joinUs.title}</Typography>
                    <Link className="footer__link" to={settings.joinUs.ctaUrl}>
                      <InlineButton withHover={true}>
                        <Typography variant={VARIANT.BODY}>{settings.joinUs.ctaText}</Typography>
                      </InlineButton>
                    </Link>
                  </React.Fragment>
                )}
                {socialLinks && (
                  <React.Fragment>
                    <Typography variant={VARIANT.BIG_BODY}>
                      <FormattedMessage id="footer.follow_us" />
                    </Typography>
                    <SocialLinks className="footer__social-links" isFooter links={socialLinks} />
                  </React.Fragment>
                )}
              </Grid>
              <Grid
                className="footer__row"
                columnsPerBreakpoint={{S: 1, M: 2}}
                itemClassName="footer__column"
                scrollAnimation={false}
              >
                {settings.footerHubspot && (
                  <>
                    <Typography variant={VARIANT.BIG_BODY}>
                      <FormattedMessage id="common.form.newsletter" />
                    </Typography>
                    <ContactForm
                      className="footer__contact-form"
                      formInfo={settings.footerHubspot}
                      invertedColor
                      visibleFromStart
                    />
                  </>
                )}
              </Grid>
            </ContentWithSidebar>
          </>
          // eslint-disable-next-line indent
        )}
      <LegalMenu items={items}></LegalMenu>
    </footer>
  );
};

const mapStateToProps = state => ({
  footerMenu: getFooterMenu(state),
});

Footer.propTypes = {
  settings: PropTypes.object,
};

Footer.defaultProps = {
  settings: {},
};

export default compose(
  connect(mapStateToProps),
  React.memo,
)(Footer);
